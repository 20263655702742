import React, { useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  allUser,
  deleteUser,
  allDevice,
  assignDevice,
  projectList,
  filterallUser,
} from "../redux/actions/userAuth";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DownloadTableExcel } from "react-export-table-to-excel";
import SearchableDropdown from "../component/filter";
import axios from "axios";
import { base_url } from "../config";

const AllUsers = () => {
  const status = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [alluser, setAllUser] = useState([]);
  const [allFiltereduser, setAllFilteredUser] = useState([]);
  const [selectProject, setSelectProject] = useState(false);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [allDevicedata, setAllDevicedata] = useState([]);
  const [checked, setchecked] = useState(false);
  const [projectLst, setProjectLst] = useState([]);
  const [filteredDevice, setFilteredDevice] = useState();
  const [filteredProject, setFilteredProject] = useState();
  const [userData, setUserData] = useState({
    device_id: "",
    device_name: "",
    device_ref_num: "",
    name: "",
    email: "",
    phone: "",
    assignedDateTime: "",
    project_id: "",
    projectName: "",
    block: "",
    panchayat: "",
    city: "",
    district: "",
    state: "",
  });

  // all device api
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isPopupOpen2, setPopupOpen2] = useState(0);
  const [checkdevice, setCheckdevice] = useState(false);
  const openPopup = (item) => {
    setUserData({
      ...userData,
      name: item.name,
      email: item.email,
      phone: item.phone,
    });
    setPopupOpen(true);
  };
  const openPopup2 = (user) => {
    setPopupOpen2(user.userId);
    if (user.deviceStatus === "assigned") {
      setCheckdevice(true);
    }
  };
  const closePopup = () => {
    setPopupOpen(false);
    setSelectProject(false);
    setchecked(false);
    setFilteredDevice(allDevicedata);
    setFilteredProject(projectLst);
    setUserData({
      ...userData,
      device_id: "",
      device_name: "",
      device_ref_num: "",
      name: "",
      email: "",
      phone: "",
      assignedDateTime: "",
      project_id: "",
      block: "",
      city: "",
      district: "",
      panchayat: "",
      state: "",
    });
  };
  const closePopup2 = () => {
    setPopupOpen2(0);
    setCheckdevice(false);
  };
  useEffect(() => {
    document.body.classList.toggle("popup-open", isPopupOpen);
  }, [isPopupOpen]);
  useEffect(() => {
    document.body.classList.toggle("popup-open", isPopupOpen2);
  }, [isPopupOpen2]);

  const deviceData = (device) => {
    setUserData({
      ...userData,
      device_id: device.DeviceID,
      device_name: device.DeviceName.String,
      device_ref_num: device.DeviceRefNum.String,
    });
    setchecked(true);
  };
  useEffect(() => {
    const apiData = {};
    dispatch(allDevice(apiData));
  }, []);
  useEffect(() => {
    dispatch(projectList());
  }, []);
  useEffect(() => {
    if (status.projectList?.successData?.data !== "") {
      if (status.projectList?.successData?.status === 200) {
        setProjectLst(status?.projectList?.successData?.data);
        setFilteredProject(status?.projectList?.successData?.data);
      }
    }
  }, [status]);
  const selectedProject = (item) => {
    setUserData({
      ...userData,
      project_id: item?.projectId,
    });
    setchecked(false);
  };
  const selectUserName = () => {
    if (checked) {
      setSelectProject(true);
    } else if (unassignDeviceLength1) {
      toast.error("No Device Available", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("Please Select Device", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const assignProject = () => {
    if (!checked) {
      const currDate = new Date();
      const foDate = moment(currDate).toISOString().slice(0, -5) + "Z";
      setUserData({
        ...userData,
        assignedDateTime: foDate,
      });
      const apiData = {
        device_id: userData?.device_id,
        device_name: userData?.device_name,
        device_ref_num: userData?.device_ref_num,
        name: userData?.name,
        // email: userData?.email,
        phone: userData?.phone,
        assignedDateTime: foDate,
        project_id: userData?.project_id,
      };
      dispatch(assignDevice(apiData));
    } else {
      toast.error("Please Select Project", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    console.log(userData, "The user data");
  });
  useEffect(() => {
    if (status.allDevice?.data !== "") {
      if (status.allDevice?.data.status === 200) {
        const allDeviceData = status.allDevice.data.data;
        const filteredAllUsers = allDeviceData?.filter(
          (item) => !deletedUsers.includes(item.device_id)
        );
        setAllDevicedata(filteredAllUsers);
        setFilteredDevice(filteredAllUsers);
      }
    }
  }, [status, deletedUsers]);

  // all device api

  const handleDeleteUser = (userId) => {
    if (userId) {
      const data = { id: userId };
      console.log("Deleting user with ID:", userId);
      dispatch(deleteUser(data));
      setAllUser((prevUsers) =>
        prevUsers?.filter((user) => user.userId !== userId)
      );
      setDeletedUsers((prevDeletedUsers) => [...prevDeletedUsers, userId]);
      toast.success("User deleted successfully! ", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setPopupOpen2(0);
    } else {
      console.error("Invalid user ID:", userId);
    }
  };

  let param = window.location.href;
  const queryString = param.split("?")[1];
  if (queryString) {
    param = queryString.split("=")[1];
  } else {
    param = "";
  }

  useEffect(() => {
    if (status.deleteUser.data !== "") {
      if (status.deleteUser.data.status === 200) {
      }
    }
  }, []);

  // delete

  useEffect(() => {
    const apiData = {};
    dispatch(allUser(apiData));
  }, []);
  const filterProject = (e) => {
    setUserData({
      ...userData,
      projectName: e.target.value,
    });
    dispatch(allUser(e.target.value, "", "", "", "", ""));
  };

  const filterProjectForBlock = async (data) => {
    setUserData({
      ...userData,
      block: data,
    });

    dispatch(filterallUser("", data, "", "", "", ""));
  };
  const filterProjectForPanchayat = async (data) => {
    setUserData({
      ...userData,
      panchayat: data,
    });

    dispatch(filterallUser("", "", data, "", "", ""));
  };
  const filterProjectForVillageOrCity = async (data) => {
    setUserData({
      ...userData,
      city: data,
    });
    dispatch(filterallUser("", "", "", data, "", ""));
  };
  const filterProjectForDistrict = async (data) => {
    setUserData({
      ...userData,
      district: data,
    });

    dispatch(filterallUser("", "", "", "", data, ""));
  };
  const filterProjectForState = async (data) => {
    setUserData({
      ...userData,
      state: data,
    });

    dispatch(filterallUser("", "", "", "", "", data));
  };

  useEffect(() => {
    if (status.allUser.data !== "") {
      if (status.allUser.data.status === 200) {
        const filteredAllUsers1 = status.allUser.data.data;
        const filteredAllUsers =
          filteredAllUsers1 !== null
            ? filteredAllUsers1?.filter(
                (item) => !deletedUsers.includes(item.userId)
              )
            : filteredAllUsers1;
        setAllUser(filteredAllUsers ? filteredAllUsers : null);
        setAllFilteredUser(filteredAllUsers ? filteredAllUsers : null);
      }
      if (status.allUser?.data?.response != null) {
        if (status.allUser?.data?.response?.status === 401) {
          toast.error(status.allUser?.data?.response?.data?.error, {
            position: "bottom-right",
            theme: "colored",
          });
          window.sessionStorage.clear();
          let path = `/`;
          navigate(path);
          window.location.reload();
        }
      }
    }
  }, [status, deletedUsers]);
  useEffect(() => {
    if (status.assignDevice?.successData !== "") {
      if (status.assignDevice?.successData?.status === 200) {
        setPopupOpen(false);
        toast.success("device assign successfully! ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }
    }
  }, [status]);
  const unassignDeviceLength = allDevicedata?.filter(
    (item) => item.BtAssigned === 0
  );
  const unassignDeviceLength1 = unassignDeviceLength?.length;

  const handleFilter = (event) => {
    const value = event.target.value.toLowerCase();
    const filtered = allDevicedata?.filter((dvc) =>
      dvc?.DeviceName?.String.toLowerCase().includes(value)
    );
    setFilteredDevice(filtered);
  };
  const handleFilter1 = (event) => {
    const value = event.target.value.toLowerCase();
    const filtered = projectLst?.filter((user) =>
      user?.projectName.toLowerCase().includes(value)
    );
    setFilteredProject(filtered);
  };

  useEffect(() => {
    console.log("all users", alluser);
  }, [alluser]);

  return (
    <>
      <div className="topSection" onClick={closePopup}>
        <div className="topSectionleft">
          <h3>
            All Users
            <span>
              <img alt="" src="../image/users.svg"></img> / All Users
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap">
          <div className="tablewrappadd filter-energy-d">
            <div className="energy-detail-header">
              <h2>All Users</h2>
              <p>Users List</p>
            </div>
            <div className="filter-data-eng-summary">
              <div className="energy-filter">
                <div className="selectfield smin-width-360">
                  <div className="customfield">
                    <span className="pageimage">
                      <img alt="img" src="../image/project.svg"></img>
                    </span>
                    <Box component="form" noValidate autoComplete="off">
                      <div>
                        <TextField
                          id="filled-select-currency"
                          select
                          label="Select Project Name"
                          variant="filled"
                          onChange={(e) => filterProject(e)}
                        >
                          <MenuItem value={""}>Select Project Name</MenuItem>
                          {projectLst?.map((option) => (
                            <MenuItem
                              key={option?.projectId}
                              value={option?.projectName}
                            >
                              {option?.projectName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </Box>
                    <img
                      alt="img"
                      src="../image/arrowb.svg"
                      className="arrowb"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="download-excel">
                <DownloadTableExcel
                  filename={`All Users Details`}
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <button>
                    <FileDownloadIcon style={{ color: "#fff" }} /> Export Excel{" "}
                  </button>
                </DownloadTableExcel>
              </div>
            </div>
          </div>
          <div className="customtabel">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                // ref={tableRef}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>User Name</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Added Date</TableCell>
                    <TableCell>
                      <SearchableDropdown
                        name="Block"
                        data={[...new Set(alluser?.map((item) => item.block))]}
                        filterProject={filterProjectForBlock}
                        allProject={filterProject}
                      />
                    </TableCell>
                    <TableCell>
                      <SearchableDropdown
                        name="Panchayat"
                        data={[
                          ...new Set(alluser?.map((item) => item.panchayat)),
                        ]}
                        filterProject={filterProjectForPanchayat}
                        allProject={filterProject}
                      />
                    </TableCell>
                    <TableCell>
                      <SearchableDropdown
                        name="village"
                        data={[...new Set(alluser?.map((item) => item.city))]}
                        filterProject={filterProjectForVillageOrCity}
                        allProject={filterProject}
                      />
                    </TableCell>
                    <TableCell>
                      <SearchableDropdown
                        name="District"
                        data={[
                          ...new Set(alluser?.map((item) => item.district)),
                        ]}
                        filterProject={filterProjectForDistrict}
                        allProject={filterProject}
                      />
                    </TableCell>
                    <TableCell>
                      <SearchableDropdown
                        name="State"
                        data={[...new Set(alluser?.map((item) => item.state))]}
                        filterProject={filterProjectForState}
                        allProject={filterProject}
                      />
                    </TableCell>
                    <TableCell>Device Status</TableCell>
                    <TableCell>Assign Device</TableCell>
                    <TableCell>User Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(allFiltereduser || alluser)?.map((item) => (
                    <TableRow
                      key={item.userId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="tfirst">
                          <div className="tfleft tfleft2">
                            <span className="pageimage">
                              {item.image ? (
                                <img alt="" src={item.image} />
                              ) : (
                                <img alt="" src={"../image/profile.svg"} />
                              )}
                            </span>
                          </div>
                          <div className="tfright tfright2">
                            <p className="green">
                              <Link to={`/dashboard/profile/${item.userId}`}>
                                {item.name}
                              </Link>
                            </p>
                            <span>{item.email}</span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="tfright">
                          <p>{item?.project_name?.String}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="tfright">
                          <p style={{ whiteSpace: "nowrap" }}>
                            {item.CreatedAt?.String}
                          </p>
                          <span>{item.transactionTime?.String}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="tfright">
                          <p>{item.block}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="tfright">
                          <p>{item.panchayat}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="tfright">
                          <p>{item.city}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="tfright">
                          <p>{item.district}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="tfright">
                          <p>{item.state}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="tfright">
                          {item.deviceStatus === "assigned" ? (
                            <>
                              <p className="greenColor">Active</p>
                              <span>{item?.device_id}</span>
                            </>
                          ) : (
                            <p className="redColor">Not Assigned</p>
                          )}
                          <span>{item.deviceRefNum?.String}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="tfright">
                          {item.deviceStatus === "assigned" ? (
                            <button onClick={() => openPopup(item)}>
                              <img alt="" src="../image/edit.svg" />
                            </button>
                          ) : (
                            <button onClick={() => openPopup(item)}>
                              <img alt="" src="../image/add.svg" />
                            </button>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="tfright">
                          <p>
                            <Link
                              to={`/dashboard/ProfileSettings/${item.userId}`}
                            >
                              <img alt="" src="../image/edit2.svg" />
                            </Link>
                            <button onClick={() => openPopup2(item)}>
                              <img alt="" src="../image/delete.svg" />
                            </button>
                          </p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!alluser && !allFiltereduser && (
              <div className="no-data-found">
                <img alt="img" src="/image/noDataFound.svg" />
              </div>
            )}
          </div>
          <div style={{ display: "none" }}>
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Project Name</th>
                  <th>Added Date</th>
                  {/* <th>Time</th> */}
                  <th>Block</th>
                  <th>Panchayat</th>
                  <th>Village/City</th>
                  <th>District</th>
                  <th>State</th>
                  <th>Device Status</th>
                  {/* <th>Device ID</th> */}
                </tr>
              </thead>
              <tbody>
                {(allFiltereduser || alluser)?.map((item) => (
                  <tr key={item.userId}>
                    <td>{item.name}</td>
                    <td>{item?.project_name?.String || ""}</td>
                    <td>
                      {item.CreatedAt?.String || ""} <br />{" "}
                      {item.transactionTime?.String || ""}
                    </td>
                    {/* <td>{item.transactionTime?.String || ""}</td> */}
                    <td>{item.block}</td>
                    <td>{item.panchayat}</td>
                    <td>{item.city}</td>
                    <td>{item.district}</td>
                    <td>{item.state}</td>
                    <td>
                      {item.deviceStatus === "assigned"
                        ? "Active"
                        : "Not Assigned"}
                      <br />
                      {`Id: ${item.device_id || ""}`}
                    </td>
                    {/* <td>{`Id: ${item.device_id || ""}`}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popbg">
            <div className="popup-content" id="style-1">
              <div className="pop-top-heading">
                <h6>Select {selectProject ? "Project" : "Device"}</h6>
                {selectProject ? (
                  <input
                    type="text"
                    onChange={handleFilter1}
                    className="user-filter"
                    placeholder="Filter project name"
                  />
                ) : (
                  <input
                    type="text"
                    onChange={handleFilter}
                    className="user-filter"
                    placeholder="Filter device name"
                  />
                )}
              </div>
              <div className="popup-inner-body">
                {selectProject === true ? (
                  filteredProject?.map((projectList, i) => (
                    <div className="poptext project-class">
                      <label htmlFor={`inputpro${i}`}>
                        <p onClick={() => selectedProject(projectList)}>
                          <b>{projectList?.projectName}</b>
                          <span># {projectList?.projectId}</span>
                        </p>
                      </label>
                      <input
                        name="project"
                        type="radio"
                        value={projectList?.projectId}
                        id={`inputpro${i}`}
                      />
                      <span className="radio-btn-check"></span>
                    </div>
                  ))
                ) : unassignDeviceLength1 !== 0 ? (
                  filteredDevice?.map((allDevicedata, i) => (
                    <div className="poptext" key={allDevicedata.DeviceID}>
                      {allDevicedata && allDevicedata["BtAssigned"] === 0 ? (
                        <>
                          <label htmlFor={`input${i}`}>
                            <p onClick={() => deviceData(allDevicedata)}>
                              <b>{allDevicedata.DeviceName.String}</b>
                              <span>{allDevicedata?.DeviceID}</span>
                            </p>
                          </label>
                          <input
                            name="user"
                            type="radio"
                            value={alluser?.userId}
                            id={`input${i}`}
                          />
                          <span className="radio-btn-check"></span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ))
                ) : (
                  <div className="no-device-avl">No Device Available</div>
                )}
              </div>
              {selectProject ? (
                <button
                  className="popupCloseBtn greenbtn"
                  onClick={assignProject}
                >
                  Assign
                </button>
              ) : (
                <button
                  className="popupCloseBtn greenbtn"
                  onClick={selectUserName}
                >
                  ok
                </button>
              )}
              <button className="popupCloseBtn" onClick={closePopup}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {isPopupOpen2 !== 0 && (
        <div className="popup-overlay">
          <div className="popbg">
            <div className="popup-content" id="style-1">
              <div className="pop-top-heading">
                <h6>
                  {checkdevice ? "Can't delete this user" : "Delete User"}
                </h6>
              </div>
              <div className="popup-inner-body">
                <p>
                  {checkdevice
                    ? "Because device assigned to this user"
                    : "Are you sure you want to delete this User?"}
                </p>
                <p>User Id - {isPopupOpen2}</p>
              </div>
              {!checkdevice && (
                <button
                  className="popupCloseBtn red-btn"
                  onClick={() => handleDeleteUser(isPopupOpen2)}
                >
                  Delete
                </button>
              )}
              <button className="popupCloseBtn" onClick={closePopup2}>
                {checkdevice ? "ok" : "cancel"}
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default AllUsers;
